$breakpoint-tablet: 768px;
$breakpoint-laptop: 998px;
$breakpoint-xl-laptop: 1366px;

.container {
    margin:0 auto;
    padding: 20px 0;
    max-width: 1200px;
    width: 100%;
    flex: 1;

    iframe {
        max-width: 100%;
    }

    @media (max-width: $breakpoint-xl-laptop) {
        max-width: 900px;
    }
    @media (max-width: $breakpoint-laptop) {
        max-width: 100%;
        padding: 20px 24px;
    }
    @media (max-width: $breakpoint-tablet) {
        max-width: 90%;
        padding: 20px 12px;
    }
}
